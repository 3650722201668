@import "../abstracts/variables";

.sellerheader {
    margin-top: 0px;
    // margin-bottom: 5px;
    position: fixed;
    z-index: 3;
    background-color: #ffffff;
    display: initial;
    padding-left: 17px;
    padding-right: 40px;
    width: 100%;
    padding-top: 10px;
    margin-bottom: 19px;
    .MuiSvgIcon-root {
        margin-top: 6px;
        margin-left: 9px;
        width: 31px;
        height: 31px;
        color: #FFFFFF !important;
      }
    .header-seller {
        display: flex;
        // padding-left: 25px;
        align-items: center;
    }

    .logo-img {
        // width: 270px;
        width: 250px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        cursor: pointer;
    }

    .login-btn {
        background-color: #949d8c;
        width: 128px;
        border-radius: 28px;
        color: #ffff;
        height: 47px;
        font-family: "Asap";
        outline: none;
        border: none;
        margin-left: 20px;
        border-radius: 40px;
        font-size: 16px;
        cursor: pointer;
    }

    .category-section {
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        right: 30px;
    }

    .category-name {
        text-align: left;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: 0px;
        color: #2f2b23;
        opacity: 1;
        font-family: 'asap';
        padding-left: 25px;
        cursor: pointer;

    }
}

@media (min-width:280px) and (max-width:600px) {
    .sellerheader {
        .logo-img {
            width: 200px;
            height: 55px;

        }

        .mobilemenu {
            position: absolute;
            right: 0;
            top: 12px;
            float: right;
            width: 77px;
            height: 54px;
            background-color: #949d8c;
            border-radius: 39px;
            opacity: 1;
            right: 22px;

            img {
                -webkit-filter: invert(1);
                filter: invert(1);
            }
        }

        .menu-btn {
            float: right;
            width: 77px;
            height: 54px;
            background-color: #949d8c;
            border-radius: 39px;
            opacity: 1;
            color: #ffffff;
            right: 22px;
        }

        .css-zddlty-MuiButtonBase-root-MuiButton-root:hover {
            float: right;
            width: 77px;
            height: 54px;
            background-color: #949d8c;
            border-radius: 39px;
            opacity: 1;
            color: #ffffff;
            right: 22px;
        }

        img.icon-img {
            filter: invert(1);
        }

        .category-section.desktopshow {
            display: none !important;
        }

        .MuiPaper-rounded {
            border-radius: none !important;
        }

        .category-name {
            margin-bottom: 15px;
        }
    }
}
@media (min-width:601px) and (max-width:919px) {
    .sellerheader{
        .category-section.desktopshow {
            display: none !important;
        }
        .mobilemenu{
            position: absolute;
            right: 0;
            top: 12px;
            float: right;
        }
    }

}
@media (min-width:920px) and (max-width:1280px) {
.sellerheader {
  .category-name {
        font-size: 16px;
        line-height: 23px;
        padding-left: 20px;
    }
    .logo-img{
        width: 180px;
    }
    .login-btn {
        width: 101px;
     padding: 0; 
        height: 45px;
    }
}
}
@media (min-width:1921px) and (max-width:6000px) {
    .sellerheader {
        padding-top: 1.04vw;
        margin-bottom: 1.04vw;
        .login-btn {
            min-width: 8.5vw;
            min-height: 3.2vw;
            text-align: center;
            font-size: 0.937vw;
            line-height: 1.04vw;
            border-radius: 2.5vw;
        }

        .headersize {
            width: 70%;
            display: block;
            position: relative;
            margin: 0 auto;
        }

        .category-name {
            font-size: 1.04vw;
            line-height: 1.25vw;
            padding-left: 1.25vw;
        }

        .logo-img {
            width: 12vw;
        }
    }
}