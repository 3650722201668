.onoffswitch{
    .MuiSwitch-root{
        width:auto !important;
        height:auto !important;
    }
    .Mui-checked{
        color:#fff !important;
    }
    .Mui-checked+.MuiSwitch-track{
        background-color: #7F6A86!important;
        opacity: 1 !important;
        
    }
}

.onoffswitch{
   .MuiSwitch-thumb {
    box-shadow: none !important;
    height: 22px !important;
    margin: 0px 0px 0px 0px !important;
    width: 22px !important;
}
.MuiSwitch-track {
    border-radius: 15px !important;
    width: 48px !important;
    height: 25px !important;
}
}

@media (min-width:2000px) and (max-width:6000px){
    .onoffswitch{
        .MuiSwitch-thumb {
            height: 19px !important;
            width: 19px !important;
        }
        .MuiSwitch-track {
            width: 46px !important;
            height: 21px !important;
        }
    } 
}