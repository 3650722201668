@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;1,400&display=swap");
@import "../scss/base/fonts";
// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendors/normalize';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
  @import 'layout/publicHeader';
  @import 'layout/publicFooter';
// 5. Components
@import
  'components/button',
  'components/pagination',
  'components/adminbrandwithcause',
  'components/addsubsection',
  'components/addto',
  'components/removefrom',
  'components/adminsidebar',
  'components/table';

// 6. Page-specific styles
@import
  'pages/home';
  @import 'pages/loginPage',
  'pages/home',
  'pages/categories',
  'pages/viewdetails',
  'pages/popularcategory',
  'pages/selleradmin',
  'pages/featuredsection',
  'pages/pendingorders',
  'pages/buyeriddetails',
  'pages/selleriddetails',
  'pages/adminviewdetails.scss',
  'pages/userregistration',
  'pages/thanksforregistering.scss',
  'pages/brandwithcause';

// 7. Themes
@import
  'themes/default';
  @import 'universal/inputType';
  @import 'universal/exclusiveBlog.scss';
@import 'pages/forgotPassword';
@import 'pages/resetPassword';

@import 'universal/searchbar';
@import 'universal/allicons';
@import 'universal/onoffswitch';

@import 'pages/createAccount';
@import 'universal/progressBar';
@import 'pages/verifyYourEmail';
@import'pages/storeDetails';
@import 'pages/welcome';
@import 'pages/policyDetails';
@import 'components/welcomeAdminDashboard';
@import 'pages/proSellerRegistration';
@import 'components/sellerSideBar';
@import 'pages/seller';
@import 'components/registrationStepper';
@import 'universal/productCards';
@import 'pages/productImages';
@import 'universal/heading';
@import 'pages/products';
@import 'pages/addSingleProduct';
@import 'components/mediaLibrary';
@import 'pages/seller-dashboard';
@import 'universal/dashboardchart';
@import 'components/uploadImage';
@import 'pages/_orders';
@import 'pages/sellerOrderDetails';
@import 'pages/myProfile';
@import 'pages/financialreports';

@import 'components/updatePassword';
@import 'components/updateBusinessDetails';
@import 'components/updateBankdetails';
@import 'universal/dropDown.scss';
@import 'components/dispatchOrder';
@import 'pages/orderInvoice';
@import 'pages/trendingProducts';
@import 'pages/orderItemReport';
@import 'universal/fileUpload';
@import 'pages/contactUs';
@import 'pages/giftVoucher';

@import 'components/refund';
@import 'components/publishUpdate';
@import 'universal/emptyImages';
@import "pages/notFound";
@import "pages/noInternet";
@import './components/skeleton/dashboardskeleton';
@import './components/skeleton/productimageskeleton';
@import './components/skeleton/productskeleton';
@import './components/skeleton/orderskeleton';
@import './components/skeleton/financalreportskeleton';
@import './components/sweetalert';
@import './components/editImage';
@import './components/skeleton/sellerSkeleton';
@import './pages/bulkUpload';

.goethical_text{
  cursor: pointer;
  color: #757061;
}
@media (min-width:2000px) {
  .all-container-27inch{
    max-width: 65%  !important;
  }
  .container-27-admin{
    max-width: 65%  !important;
  }
}
@media  (min-width:1400px) and (max-width:1930px) {
.container-27-admin{
  max-width: 100%  !important;
}
}
@media  (min-width:1800px) and (max-width:1999px) {
  .all-container-27inch{
    max-width: 1450px  !important;
  }
}
@media  (min-width:1180px) and (max-width:1400px) {
  .all-container-27inch{
    max-width: 94%  !important;
  }
  .container-27-admin{
    max-width: 100%  !important;
  }
}
.css-honmcm-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
  margin-top: 48px;
  padding-left: 0px !important;
}
.css-1fttrmn-MuiGrid-root {
  background: #faf9f9;
}
.css-1j1gpuc{
  background: #faf9f9;
}


.sellerorder
.MuiButtonBase-root{
  display: block !important;
  padding: 4px 10px !important;
}

  li.MuiButtonBase-root{
    display: block !important;
  padding: 8px 16px !important;
  }

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  display: block !important;
}
// .all-container-27inch {
//   max-width: 90% !important;
// }

.input-error-message {
  font-size: 12px;
  color: #fc4b6c !important;
  display: block;
}
  .mobilehide {
    display: block;
  }
  
  .mobileshow {
    display: none;
  }
  .tabhide {
    display: block;
  }
  
  .tabshow {
    display: none;
  }
  .main-heading{
    text-align: center;
    font-size: 72px;
    letter-spacing: 0px;
    color: #2f2b23;
    opacity: 1;
    font-family: 'GT-Super-Text-Regular';
    margin-bottom: 49px;
        opacity: 0.9;
  }
  .register-text-heading{
    font-size: 2.29vw;
    line-height: 2.60vw;
    text-align: center;
    letter-spacing: 0px;
    color: #2f2b23;
    opacity: 1;
    font-family: 'GT-Super-Text-Regular';
    margin-bottom: 65px;
  }
  .seller-para{
    font-family: "Asap";
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    color: #757061;
    margin-bottom: 32px;
    padding-right: 21vw;
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
   
    min-width: 360px;
    width: 600px !important;
    border-radius: 8px !important;
  }
   .css-uhb5lp {
    min-width: 360px;
    width: 600px !important;
    border-radius: 8px !important;
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
   
    min-width: 360px;
    width: 500px !important;
    border-radius: 8px !important;
  }
  .css-uhb5lp {
    min-width: 360px;
    width: 500px !important;
    border-radius: 8px !important;
  }
 
  .sellerdashboard{
    background-color: rgb(250, 249, 249);
}
  @media only screen and (max-width: 600px) {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
   
      min-width: auto !important;
      width: 500px !important;
    }
    .css-honmcm-MuiGrid-root>.MuiGrid-item {
      padding-top: 0px !important;
      margin-top: 0px;
      padding-left: 0px !important;
    }
    .css-uhb5lp {
      min-width: auto !important;
      width: 500px !important;
    }
    .mobilehide {
      display: none;
    }
    
    .mobileshow {
      display: block;
    }
     .register-text-heading{
font-size: 18px;
line-height: 20px;
    }
  }

  @media only screen and (max-width: 919px) {
    .tabhide {
      display: none;
    }
    
    .tabshow {
      display: block;
    }
  }


  @media only screen and (min-width: 1921px) {
   .main-heading{
    font-size: 3.5vw;
    line-height: 3.8vw;
    margin-bottom: 2.5vw;
   }
   .sellerorder .MuiButtonBase-root {
    padding: 0.2vw 0.5vw !important;
   }
   .swal2-popup {
    width: 28vw !important;
    padding: 1vw !important;
   }
   .swal2-title {
    font-size: 1.2vw !important;
    line-height: 1.5vw !important;
    margin-bottom: 1.5vw;
   }
   .swal2-html-container {
    line-height: 1.2vw;
    font-size: 1vw !important;
    margin-bottom: 0.88vw !important;
   }
   .swal2-cancel, .swal2-confirm{
    border-radius: 0.5vw !important;
    padding: 0.4vw 0.8vw !important;
    font-size: 0.8vw !important;
    line-height: 1.4vw !important;
    height: auto;
    margin-top: 1vw;
   }
   input:-webkit-autofill{
    -webkit-text-fill-color: #949d8c !important;
}
   .input-error-message{
    font-size: 0.88vw;
    line-height: 1.5vw;
   }
   .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
   
    min-width: 360px;
    width: 35vw !important;
    max-width: 35vw !important;
  }
  .css-uhb5lp {
    min-width: 360px;
    width: 35vw !important;
    max-width: 35vw !important;
  }
  .MuiSvgIcon-root {
    width: 1.3vw !important;
    height: 1.3vw !important;
  }
    }

    // #root{
    //   background: rgb(250, 249, 249);
    // }
   .bgcoloradmin{
    background: rgb(250, 249, 249);

    .all-container-27inch {
      margin-top: 16px;
      padding-right: 0px;
    }
   }
 button{
  letter-spacing: 0 !important;
 }